.carousel-host {
  position: relative;
  max-width: 100%;
}

.carousel-host .scroll-container {
  overflow-x: auto;
  scroll-behavior: smooth;
  scroll-snap-type: both mandatory;
  white-space: nowrap;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.carousel-host .scroll-container::-webkit-scrollbar {
  display: none;
}

.carousel-host .scroll-container .panel {
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  white-space: normal;
}
