.markdown {
  h1 {
    font-size: 2.5em;
  }

  h2 {
    font-size: 2.0em;
  }

  h3 {
    font-size: 1.5em;
  }

  h4 {
    font-size: 1.2em;
  }

  h5 {
    font-size: 1.0em;
  }

  h6 {
    color: #777;
    font-size: 1.0em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    line-height: 1.7;
  }

  h1+p,
  h2+p,
  h3+p {
    margin-top: 6px;
  }

  p,
  blockquote,
  ul,
  ol,
  dl,
  table,
  pre {
    margin: 4px 0;
  }

  ul,
  ol {
    padding-left: 8px;
    list-style-type: none;
  }
  a {
    color: #4183c4;
    text-decoration: none;
    }
  
  a:hover {
    text-decoration: underline;
    }
}